import { Control } from "@components";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Tooltip } from "@mui/material";
import mapboxgl from "mapbox-gl";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { MapRef } from "react-map-gl";
import { EnableLocationAccessDialog } from "../../EnableLocationAccessDialog";
import { IViewState } from "../../location-editor/LocationSelect";

interface IGeolocateControlProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
    /**
     * Determines if the control should automatically show the user's location
     *
     * @default true
     */
    showLocation?: boolean;
    /**
     * Current view state of the map
     */
    viewState: IViewState;
}

/**
 * Control for geolocating the user
 */
export const GeolocateControl = ({
    mapRef,
    viewState,
    showLocation = true,
}: IGeolocateControlProps) => {
    const geolocateRef = useRef<mapboxgl.GeolocateControl | null>(null);

    const [userLocation, setUserLocation] =
        useState<GeolocationPosition | null>();

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const mapOnUserLocation =
        viewState.latitude.toFixed(8) ===
            userLocation?.coords.latitude.toFixed(8) &&
        viewState.longitude.toFixed(8) ===
            userLocation.coords.longitude.toFixed(8);

    useEffect(() => {
        // Wait for the map to be initialized
        if (!mapRef.current) return;

        const handleMapLoad = () => {
            if (geolocateRef.current && showLocation) {
                geolocateRef.current.trigger();
            }
        };

        const geolocate = new mapboxgl.GeolocateControl({
            fitBoundsOptions: {
                maxZoom: 15,
                duration: 0,
            },
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: false,
            showUserLocation: true,
            showAccuracyCircle: true,
            showUserHeading: true,
        });

        geolocateRef.current = geolocate;
        mapRef.current.addControl(geolocate);

        // If the map is already loaded, trigger the geolocate control
        if (mapRef.current.isStyleLoaded()) {
            handleMapLoad();
        } else {
            mapRef.current.on("load", handleMapLoad);
        }

        geolocateRef.current.on("geolocate", (locate) => {
            setUserLocation(locate);
        });

        // listen for errors
        geolocateRef.current.on("error", (error) => {
            // If error is caused by permission denied, open the dialog
            if (error.code === error.PERMISSION_DENIED) {
                setDialogOpen(true);
            } else {
                console.error("Error getting user location:", error);
            }
        });

        return () => {
            if (mapRef.current) {
                mapRef.current.removeControl(geolocate);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                mapRef.current.off("load", handleMapLoad);
            }
        };
    }, [mapRef, showLocation]);

    const handleGeolocate = () => {
        if (geolocateRef.current) {
            geolocateRef.current.trigger();
        }
    };

    return (
        <>
            <Tooltip
                title={"Go to your location"}
                PopperProps={{
                    disablePortal: true,
                }}
                placement="left"
            >
                <span>
                    <Control
                        onClick={handleGeolocate}
                        disabled={!mapRef.current || mapOnUserLocation}
                    >
                        <MyLocationIcon />
                    </Control>
                </span>
            </Tooltip>
            <EnableLocationAccessDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
            />
        </>
    );
};
