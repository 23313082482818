import { ICollection, ImageType } from "@api";
import { MAPBOX_TOKEN } from "@config";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tile } from "../MapPage";

interface IProps {
    /**
     * Collection for the location
     */
    collection: ICollection;
    /**
     * Tiles for the collection
     */
    tiles: Tile[] | undefined;
    /**
     * Set the tiles for the collection
     */
    setTiles: React.Dispatch<React.SetStateAction<Tile[] | undefined>>;
    /**
     * Set the current tile
     */
    setTile: React.Dispatch<React.SetStateAction<Tile | undefined>>;
    tile?: Tile;
}

/**
 * Layer control displays a menu to select the image layer
 */
export const DroneImageLayerSwitcher = ({
    collection,
    tiles,
    setTiles,
    setTile,
    tile,
}: IProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();
    const [imageType] = useState(searchParams.get("imageType"));

    const collectionImages = collection.images.sort((a) =>
        a.type === ImageType.RGB ? -1 : 0
    );

    const availableLayers = collectionImages.filter((image) => {
        return image.upload_complete;
    });

    const handleLayerChange = useCallback(
        (imageType: ImageType) => {
            if (!tiles) return;

            // find the new tile based on the image type
            const newTile = tiles.find((t) => t.imageType === imageType);

            // set the new tile if it exists
            if (newTile) {
                setTile(newTile);
            }
        },
        [tiles, setTile]
    );

    // Set the layer to the selected image type if it exists in the search params
    useEffect(() => {
        if (imageType) {
            handleLayerChange(imageType as ImageType);
        }
    }, [handleLayerChange, imageType, tiles]);

    // Set tiles when collection is loaded
    useEffect(() => {
        if (collection) {
            // filter out images that have not been uploaded
            const validImages = collection.images.filter(
                (image) => image.upload_complete
            );

            // Set tiles with images that have been uploaded and sort by RGB first
            const images = validImages
                .map((image) => ({
                    tileRef: `https://api.mapbox.com/v4/${image.tileset_id}/{z}/{x}/{y}.png?access_token=${MAPBOX_TOKEN}`,
                    imageType: image.type,
                    createdAt: image.created_at,
                    image: image,
                }))
                .sort((a) => (a.imageType === ImageType.RGB ? -1 : 0));

            setTiles(images);
            setTile(images[0]);
        } else {
            setTiles(undefined);
        }
    }, [collection, setTile, setTiles]);

    return (
        <FormControl>
            <InputLabel id="layer-select-label">Drone Image Layer</InputLabel>
            <Select
                labelId={"layer-select-label"}
                value={tile ? tile.imageType : ""}
                size={isMobile ? "small" : "medium"}
                renderValue={(value) => {
                    const layer = availableLayers.find(
                        (layer) => layer.type === value
                    );
                    return layer ? layer.type : "";
                }}
                onChange={(value) => {
                    handleLayerChange(value.target.value as ImageType);
                }}
                label={"Drone Image Layer"}
                sx={{
                    width: { xs: 80, sm: 140, md: "auto" },
                    minWidth: { xs: 80, sm: 140, md: 200 },
                }}
            >
                <MenuItem
                    id={"menu-header"}
                    value={"Drone Image Layers"}
                    disableRipple
                    dense
                    sx={{
                        backgroundColor: "Menu",
                        ":hover": {
                            backgroundColor: "#ffffff",
                            cursor: "default",
                        },
                    }}
                >
                    Drone Image Layers
                </MenuItem>
                {availableLayers.map((layer) => (
                    <MenuItem key={layer.id} value={layer.type} dense>
                        {layer.type}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
