import { IGeometryCreate } from "@api";
import AddIcon from "@mui/icons-material/Add";
import { FormButton as Button } from "@rhf-kit/mui";
import { Feature } from "geojson";
import React from "react";
import { EnableLocationAccessDialog } from "../EnableLocationAccessDialog";

interface IProps {
    setNewGeometry: (geometry: IGeometryCreate | undefined) => void;
}

export const AddAtCurrentLocation = ({ setNewGeometry }: IProps) => {
    const [isBusy, setIsBusy] = React.useState(false);

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleClicked = () => {
        setIsBusy(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    const geojson: Feature = {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [longitude, latitude],
                        },
                        id: "new-at-current-location",
                        properties: {},
                    };

                    setNewGeometry({
                        geojson,
                    });
                    setIsBusy(false);
                },
                (error) => {
                    setIsBusy(false);
                    if (error.code === error.PERMISSION_DENIED) {
                        setDialogOpen(true);
                    }
                },
                {
                    enableHighAccuracy: true,
                    timeout: 12000,
                    maximumAge: 30000,
                }
            );
        } else {
            setIsBusy(false);
            console.error("Geolocation is not supported by this browser.");
        }
    };

    return (
        <>
            <Button
                startIcon={<AddIcon />}
                variant={"contained"}
                size="small"
                onClick={handleClicked}
                loading={isBusy}
                loadingPosition="end"
            >
                Add at current location
            </Button>
            <EnableLocationAccessDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
            />
        </>
    );
};
