import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import {
    FormAutoCompleteElement,
    FormButton,
    FormCheckboxElement,
    FormContainer,
    FormEmailElement,
    FormTextFieldElement,
} from "@rhf-kit/mui";
import {
    useMutation,
    useQuery,
    useQueryClient,
    useSuspenseQuery,
} from "@tanstack/react-query";
import {
    createOrganizationUser,
    getOrganizations,
    deleteOrganizationAccount,
    updateUser,
    IUserUpdate,
    IOrganization,
    getUserById,
} from "@api";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { BackButton, DashboardContainer } from "@components";

interface IFormData {
    first_name: string;
    last_name: string;
    email: string;
    organizations: IOrganization[];
    is_admin: boolean;
}

const EditUsers = () => {
    const queryClient = useQueryClient();
    const { userId } = useParams<{ userId: string }>();
    const navigate = useNavigate();

    const { data: selectedUser } = useSuspenseQuery({
        queryKey: ["user", userId],
        queryFn: () => getUserById(Number(userId)),
    });

    const { mutate, isPending: mutationPending } = useMutation({
        mutationFn: updateUser,
        onSuccess: () => {
            enqueueSnackbar("User details updated successfully", {
                variant: "success",
            });
            queryClient.invalidateQueries({ queryKey: ["user", userId] });
            navigate(-1);
        },
        onError: () => {
            enqueueSnackbar("An error occurred while updating user details", {
                variant: "error",
            });
        },
    });

    const addUserToOrganizationMutation = useMutation({
        mutationFn: createOrganizationUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
        },
    });

    const deleteUserFromOrganizationMutation = useMutation({
        mutationFn: deleteOrganizationAccount,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
        },
    });

    const handleSaveChanges = (data: IFormData) => {
        mutate({
            id: selectedUser.id,
            data: {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                is_admin: data.is_admin,
            } as IUserUpdate,
        });

        const selectedOrganizations = data.organizations;

        const addedOrganizations = selectedOrganizations.filter(
            (org) =>
                !selectedUser.organization_accounts
                    .map((account) => account.organization)
                    .some((userOrg) => userOrg.id === org.id)
        );

        const removedOrganizations = selectedUser.organization_accounts
            .map((account) => account.organization)
            .filter(
                (userOrg) =>
                    !selectedOrganizations.some((org) => org.id === userOrg.id)
            );

        for (const org of addedOrganizations) {
            addUserToOrganizationMutation.mutate({
                organizationId: org.id,
                userId: selectedUser.id.toString(),
            });
        }

        for (const org of removedOrganizations) {
            const organizationAccount = selectedUser.organization_accounts.find(
                (account) => account.organization.id === org.id
            );

            if (!organizationAccount) return;

            deleteUserFromOrganizationMutation.mutate(organizationAccount.id);
        }
    };

    const { data: organizationOptions, isPending: organizationOptionsLoading } =
        useQuery({
            queryKey: ["organization-options"],
            queryFn: () => getOrganizations(),
        });

    return (
        <DashboardContainer title={"Users"}>
            <Box>
                <BackButton />
            </Box>
            <Card
                elevation={1}
                sx={{
                    width: "100%",
                    p: 4,
                    maxWidth: "lg",
                }}
            >
                <CardContent>
                    <Stack alignItems="center" direction="row" sx={{ mb: 2 }}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            flexGrow={1}
                            textAlign="center"
                        >
                            Edit User
                        </Typography>
                    </Stack>
                    <FormContainer
                        defaultValues={{
                            first_name: selectedUser.first_name,
                            last_name: selectedUser.last_name,
                            email: selectedUser.email,
                            organizations:
                                selectedUser.organization_accounts.map(
                                    (account) => account.organization
                                ),
                            is_admin: selectedUser.is_admin,
                        }}
                        onSuccess={handleSaveChanges}
                    >
                        <Stack spacing={3}>
                            <FormTextFieldElement
                                sx={{ width: "100%" }}
                                name="first_name"
                                label="First Name"
                                required
                            />
                            <FormTextFieldElement
                                sx={{ width: "100%" }}
                                name="last_name"
                                label="Last Name"
                                required
                            />
                            <FormEmailElement
                                sx={{ width: "100%" }}
                                name="email"
                                label="Email"
                                required
                                renderIcon={false}
                                disabled={selectedUser.is_superuser}
                            />
                            <FormAutoCompleteElement
                                name="organizations"
                                label="Organizations"
                                loading={organizationOptionsLoading}
                                options={organizationOptions?.items ?? []}
                                getOptionLabel={(option) =>
                                    option?.name ?? option
                                }
                                multiple
                            />
                            <FormCheckboxElement
                                name="is_admin"
                                label="Is Admin"
                                disabled={selectedUser.is_superuser}
                            />{" "}
                            <FormButton
                                variant="contained"
                                color="primary"
                                loading={mutationPending}
                            >
                                Save Changes
                            </FormButton>
                        </Stack>
                    </FormContainer>
                </CardContent>
            </Card>
        </DashboardContainer>
    );
};

export default EditUsers;
