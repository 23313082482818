import { DashboardContainer } from "@components";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { UsersTable } from "./components";
import { useNavigate } from "react-router-dom";
import { generateRoute, appRoutes } from "@config";

const Users = () => {
    const navigate = useNavigate();

    const handleAddUserClick = () => {
        navigate(generateRoute(appRoutes.admin.createUser, {}));
    };
    return (
        <DashboardContainer
            title={`Users`}
            action={
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    size="small"
                    onClick={handleAddUserClick}
                >
                    add user
                </Button>
            }
        >
            <UsersTable />
        </DashboardContainer>
    );
};

export default Users;
